<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" md="6"> </v-col>
      <v-spacer />
      <v-col class="shrink"> </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="exportRecords"
              v-on="on"
              color="msaBlue"
              class="white--text"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          Export
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Sync Records
            <v-badge
              :content="recordsCount"
              :value="recordsCount"
              bordered
              class="ml-2"
              color="msaBlue"
              inline
            >
            </v-badge>
          </v-card-title>
          <v-data-table
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :headers="tableHeaders"
            :items="records"
            :options.sync="options"
            :server-items-length="recordsCount"
            must-sort
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              {{
                item.createdAt
                  | filterAsLocalDateTime((mask = 'yyyy-LL-dd h:mm a'))
              }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SyncLogsPage',
  data() {
    return {
      defaultSortBy: 'createdAt',
      options: {
        sortBy: [],
        sortDesc: [true],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      records: [],
      recordsCount: 0,
      selectedFilters: {},
      tableHeaders: [
        {
          text: 'Device ID',
          value: 'deviceId',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '320px',
          sortable: false,
        },
        {
          text: 'Sync ID',
          value: 'syncGuid',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '320px',
          sortable: false,
        },
        {
          text: 'Controller',
          value: 'controller',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '140px',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'action',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '140px',
          sortable: false,
        },
        {
          text: 'Synced',
          value: 'createdAt',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '175px',
        },
      ],
    };
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        const changed = Object.keys(oldVal).some(
          (key) => oldVal[key].toString() != newVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.options.sortBy[0] = this.defaultSortBy;
    this.getRouteQueries();
    await this.getRecords();
    this.getUserDetails();
  },
  methods: {
    updateUrl() {
      const params = this.$helpers.serializeOptions(
        this.options,
        this.defaultSortBy,
        true,
      );
      this.$router
        .replace({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    getUserDetails() {
      const params = {
        employeeId: this.$route.params.id,
      };
      if (params.employeeId == this.$store.getters.selectedUser.id) {
        return;
      }
      // Clear the user's name in the breadcrumbs, and then update them
      this.$store.commit('updateSelectedUser', {
        firstName: '',
        lastName: '',
      });
      const url = 'get-user-details?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.userInformation = { ...response.data };
          this.$store.commit('updateSelectedUser', {
            id: this.userInformation.id,
            username: this.userInformation.username,
            firstName: this.userInformation.firstName,
            lastName: this.userInformation.lastName,
            status: this.userInformation.status,
          });
        })
        .catch((error) => error);
    },
    getRouteQueries() {
      const query = this.$route.query;
      this.options = this.$helpers.deserializeOptions(
        query,
        this.defaultSortBy,
        true,
      );
    },
    exportRecords() {
      const params = {
        syncGuid: this.$route.params.guid,
        userId: this.$route.params.id,
        options: this.options,
        filters: this.filters,
        loaderText: 'Exporting...',
      };
      let url = 'export-connection-events-by-sync-guid?format=json';
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      this.$axios
        .post(url, params, options)
        .then((response) => {
          this.$helpers.downloadFile(response.data, 'sync records.xlsx');
        })
        .catch((error) => error);
    },
    getRecords() {
      const params = {
        options: this.options,
        filters: this.filters,
        loaderText: 'Loading...',
        syncGuid: this.$route.params.guid,
        userId: this.$route.params.id,
      };
      let url = 'get-connection-events-by-sync-guid?format=json';
      return this.$axios
        .post(url, params)
        .then((response) => {
          this.records = response.data.items;
          this.recordsCount = response.data.count;
        })
        .catch((error) => error);
    },
  },
};
</script>

<style></style>
